@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=Roboto:wght@100;300;400;500;700&display=swap');


.layout-login {
    min-height: 100vh;
}

.layout-login .col-welcome {
    padding: 4rem;
    font-family: 'Roboto';
    background-image: url("../../../public/img/background/sneacare-bg.png");
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}


.layout-login .col-welcome .title-isyt-erp {
    color: white !important;
    font-family: 'Roboto';
    font-weight: 700 !important;
    font-size: 40px !important;
    margin-top: 5rem;
}

.layout-login .col-welcome .subtitle-isyt-erp {
    color: white !important;
    font-family: 'Roboto';
    font-size: 18px !important;
}

.layout-login .login-form-container {
    /*padding-right: 5rem;*/
    font-family: 'Roboto';
}

.layout-login .login-form-container .login-form {
    max-width: 400px;
    font-family: 'Roboto';
    margin: 17% auto 0 auto;
}

.layout-login .login-form-container .login-form .input-email {
    margin-top: 3rem;
    font-family: 'Roboto';
}

.layout-login .link-password-recovery {
    //position: absolute;
    padding-top: 0.5rem;
    font-family: 'Roboto';
    bottom: 0;
    //right: 0;
    left: 0;
    margin-bottom: 1rem;
   // margin-right: 2rem;
    font-weight: 600;
    color: #7A7A9D;
}

.layout-login .password-recovery-form {
    max-width: 550px;
    margin: 25% auto 0 auto;
    padding-right: 2rem;
    font-family: 'Roboto';
}

.layout-login .password-recovery-form .input-name {
    margin-top: 3rem;
    font-family: 'Roboto';
}

.btn-facebook,
.btn-facebook:hover,
.btn-facebook:focus{
    background-color: #4267b2;
    border: none;
}
.logo-login {
   cursor: pointer;
}

@media(max-width:1131px) {
    .logo-login {
        width: 100%;
    }
    
}
@media(max-width:768px) {
    .layout-login .col-welcome img{
        width: 300px;
        text-align: center;
    }
}
@media(max-width:568px) {
    .layout-login .login-form-container {
      padding: 0 2rem;
    }
    .link-password-recovery{
        //text-align: center;
    }
    .layout-login .col-welcome img{
        width: 200px;
    }
}